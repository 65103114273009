import React from 'react'
import { Link } from 'gatsby'
import '../assets/scss/layout/_harmonycavern.scss'
import albumart from '../assets/images/epart.jpg'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const HarmonyCavernTemplate = props => (
  <section id="HarmonyCavern" className="harmonycavernbg major">
    <div className="inner">
      <h3>
        <span id="album">Melancholia</span> - <span id="artist">Sebrene</span>
      </h3>
      <div className="grid-wrapper">
        <div className="col-2"></div>
        <div className="col-5">
          <div id="albumart">
            <span className="image fit">
              <img src={albumart} alt="" />
            </span>
          </div>
        </div>

        <div className="col-3">
          <AnchorLink href="#" className="button storebutton spotify">
            Stream
          </AnchorLink>
          <AnchorLink href="#" className="button storebutton applemusic">
            Stream
          </AnchorLink>
          <AnchorLink href="#" className="button storebutton soundcloud">
            Stream
          </AnchorLink>
          <AnchorLink href="#" className="button storebutton itunes">
            Buy
          </AnchorLink>
          <AnchorLink href="#" className="button storebutton amazon">
            Buy
          </AnchorLink>
          <AnchorLink href="#" className="button storebutton googleplay">
            Buy
          </AnchorLink>
        </div>
      </div>

      <div className="col-2"></div>
    </div>
  </section>
)

export default HarmonyCavernTemplate
