import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import LyricsLanding from '../components/LyricsLanding'
import Cat from '../assets/images/kitty.jpg'
import HarmonyCavern from '../components/HarmonyCavernTemplate'

const Melancholia = props => (
  <Layout>
    <Helmet>
      <title>Melancholia - Lyrics</title>
      <meta name="description" content="Lyrics" />
    </Helmet>

    <HarmonyCavern />
    <div id="main" className="alt">
      <section id="preorder">
        <div className="inner">
          <div className="grid-wrapper">
            <div className="col-4"></div>

            <div className="col-4"></div>

            <div className="col-4"></div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default Melancholia
